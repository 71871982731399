<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 15px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;color:#fff; font-weight: bold;">出院数据</span>
        </div>
        <div class="time_box">
            <!-- <van-field readonly clickable label="选择日期" :value="value" placeholder="选择日期" @click="showPicker = true" />
            <van-popup v-model="showPicker" round position="bottom">
                <van-datetime-picker v-model="value" type="date" title="选择年月日" @cancel="showPicker = false"
                    @confirm="onConfirm" />
            </van-popup> -->
            <van-cell title="选择日期区间" :value="date" @click="showPicker = true" />
            <van-calendar v-model="showPicker" type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />
        </div>
        <div id="main" style=" height: 300px;background-color: #fff;margin: 40px 20px 0px 20px;"  v-if="dataList.length>0"></div>
         <van-empty image="search" description="暂无数据" v-if="dataList.length==0"/>
        <!-- <div id="main" ></div> -->
    </div>
</template>
<script>
import dateUtil from '@/api/dateUtil.js';
import { getouthospitale } from "@/api/online";
import * as echarts from 'echarts';
export default {
    data() {
        return {
            date: '',
            dataList:[],
            showPicker: false,
            tenantId: localStorage.getItem('tenantId'),
            startTime: '',
            endTime: '',
            minDate: new Date(2000, 0, 1),
            maxDate: new Date(2030, 0, 31),
        }
    },
    mounted() {
        this.getdata()
    },
    methods: {
        getdata() {
            getouthospitale(
                this.tenantId,
                this.startTime,
                this.endTime,
            ).then((res) => {
                if (res.data.code == "200") {
                    this.dataList = res.data.data
                    this.$nextTick(() => {
                        this.getChart()
                    })
                }
            });
        },
        getChart() {
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            let Xarr = []
            let Yarr = []
            this.dataList.map((item) => {
                Xarr.push(item.deptName)
                Yarr.push(item.dischargePats)
            })
            const color = '#EFF6FF';
            option = {
                title: {
                    // text: 'Stacked Area Chart'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                // legend: {
                //     data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
                // },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                grid: {
                    left: 20,
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: Xarr
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '出院总数',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: Yarr,
                        itemStyle: {
                            normal: {
                                color:'#abd0fd', //折线点的颜色
                                borderColor: '#ffffff', //拐点边框颜色
                                borderWidth: 2, //拐点边框大小
                            },
                        },
                        lineStyle: {
                            color:'#abd0fd', //折线的颜色
                        },
                        areaStyle: {
                            //区域背景色
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color,
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(242,248,255,1)',
                                    },
                                ],
                                global: false,
                            },
                        },

                    },

                ]
            };

            option && myChart.setOption(option);

        },
        onConfirm(date) {
            const [start, end] = date;
            this.showPicker = false;
            this.date = `${dateUtil.formatDate1(start)}-${dateUtil.formatDate1(end)}`;
            this.startTime = dateUtil.formatDate3(start)
            this.endTime = dateUtil.formatDate3(end)
            this.getdata()
        },
        onClickLeft() {
            this.$router.back()
        },
        onClickInfo(router) {
            this.$router.push({ path: router })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background-color: #F6F6F6;
    // padding-top: 20px;
    height: 100vh;

    .nav_bar {
        text-align: center;
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 50px 0px;
    }

    .time_box {
        width: 90%;
        margin: -20px auto;
    }
}

/deep/.van-cell {
    border-radius: 10px !important;

}

.van-icon-arrow {
    color: #999;
}
</style>